import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.en"

const IndexPage = ({ location, data }) => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }
    if (typeof window !== "undefined") {
      handleResize()
      window.addEventListener("resize", () => {
        handleResize()
      })
    }
  }, [])
  return (
    <>
      <Metatags
        title="Multilógica"
        description="Mattress machinery supplier"
        url="https://newsite.multilogica.ind.br/en/"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="mattress, sewing machine, mammut, beckmann sew"
        language="en"
      />
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        {/* <GatsbyImage
          image={getImage(data.bannerTop)}
          backgroundColor={false}
          alt="mattress machines"
        /> */}
        {mobile ? (
          <GatsbyImage
            image={getImage(data.elektroteksMobile)}
            backgroundColor={false}
            alt="elektroteks-acquires-mammut"
          />
        ) : (
          <GatsbyImage
            image={getImage(data.elektroteks)}
            backgroundColor={false}
            alt="elektroteks-acquires-mammut"
          />
        )}
        <div className="h-8" />
        <div className=" text-center">
          <Link to="elektroteks-acquires-mammut">
            <button className="text-mi-yellow text-xl bg-mi-blue py-4 px-8 rounded-full hover:bg-mi-light-blue">
              More information
            </button>
          </Link>
        </div>
        <div className=" bg-mi-blue"></div>
        <div className="h-24" />
        <div className="max-w-xl mx-2 md:mx-auto text-center">
          <div className="text-mi-blue text-3xl font-semibold">
            Mattress machinery supplier
          </div>
          <div className="h-2" />
          <div className="text-mi-blue text-xl">
            High quality and performance equipment that combine with experience
            and excellence in service
          </div>
        </div>
        <div className="h-24" />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-24" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-3xl font-semibold">
              Our activities
            </div>
          </div>
          <div className="h-24" />
          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="col-span-1" />
            <div className="col-span-10 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-12 mx-auto">
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconMatress)}
                    backgroundColor={false}
                    alt="Supply of mattress machines"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Supply of mattress machines
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconMaintenance)}
                    backgroundColor={false}
                    alt="Machine sales, installation and repair"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Machine sales, installation and repair
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconTraining)}
                    backgroundColor={false}
                    alt="Operator training"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">Operator training</div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-6">
                  <GatsbyImage
                    image={getImage(data.iconDiagnosis)}
                    backgroundColor={false}
                    alt="Diagnosis, identification and sales of spare parts"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Diagnosis, identification and sales of spare parts
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-6">
                  <GatsbyImage
                    image={getImage(data.iconCustom)}
                    backgroundColor={false}
                    alt="Customized solutions and equipment suitability"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Customized solutions and equipment suitability
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconPatterns)}
                    backgroundColor={false}
                    alt="Pattern development"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">Pattern development</div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-24" />
        </div>
      </div>
      <div className="w-full">
        <div className="h-24" />
        <div className="max-w-2xl mx-2 md:mx-auto text-center">
          <div className="text-mi-blue text-3xl font-semibold">
            Representative in Brazil for machines:
          </div>
        </div>
        <div className="h-24" />
        <div className="grid grid-cols-1 md:grid-cols-12 mx-2">
          <div className="col-span-1" />
          <div className="col-span-10 grid grid-cols-1 gap-x-8 gap-y-12 mx-auto">
            <div className=" mx-auto ">
              <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="col-span-3">
                  <GatsbyImage
                    image={getImage(data.logoMammut)}
                    backgroundColor={false}
                    alt="Mammut"
                    className="mr-10"
                  />
                </div>
                <div className=" text-xl col-span-8">
                  <p>
                    In the last 140 years, the Stutznäcker Company has been
                    especialized in high performance mattress machines. Due to
                    their reliability and high performance the MAMMUT machines
                    are used in a countless number of factories and particularly
                    in companies where delivery times observance and final
                    product quality have the highest priority.
                  </p>
                </div>
                <div className="col-span-1 place-self-end">
                  <GatsbyImage
                    image={getImage(data.madeInGermany)}
                    backgroundColor={false}
                    alt="Made in Germany"
                  />
                </div>
              </div>
              <div className="h-8" />
              <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="col-span-3">
                  <GatsbyImage
                    image={getImage(data.logoBeckmann)}
                    backgroundColor={false}
                    alt="Beckmann"
                  />
                </div>
                <div className=" text-xl col-span-8">
                  <p>
                    Since 1990 Beckmann develops machines for mattress
                    production. Quality norms compromise, total commitment of
                    every one of its contibutors, impecable service and delivery
                    deadlines observance as well, has made that their customers
                    evaluate this company to a highest degree world-wide.
                  </p>
                </div>
                <div className="col-span-1 place-self-end">
                  <GatsbyImage
                    image={getImage(data.madeInGermany)}
                    backgroundColor={false}
                    alt="Made in Germany"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-24" />
        <div className="h-24" />
        <div className="bg-mi-gray">
          <div className="h-10" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-mi-blue text-2xl">
              Contact our team for more information.
            </div>
            <div className="h-2" />
            <Link to="contact">
              <button className="text-mi-yellow text-xl bg-mi-blue py-4 px-8 rounded-full hover:bg-mi-light-blue">
                Get in touch
              </button>
            </Link>
          </div>
          <div className="h-10" />
        </div>
        <div className="h-24" />
        <GatsbyImage
          image={getImage(data.bannerBottom)}
          backgroundColor={false}
          alt="mattress machines"
        />
      </div>
      <Footer />
    </>
  )
}
export default IndexPage

export const indexEN = graphql`
  query {
    iconMatress: file(relativePath: { eq: "icons/Icone_Multi_Colchao.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconPatterns: file(relativePath: { eq: "icons/Icone_Multi_Desenhos.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconDiagnosis: file(
      relativePath: { eq: "icons/Icone_Multi_Diagnostico.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconMaintenance: file(
      relativePath: { eq: "icons/Icone_Multi_Manutencao.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconCustom: file(
      relativePath: { eq: "icons/Icone_Multi_Personalizado.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconTraining: file(
      relativePath: { eq: "icons/Icone_Multi_Treinamento.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    logoMammut: file(relativePath: { eq: "logos/mammut.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 292)
      }
    }
    logoBeckmann: file(relativePath: { eq: "logos/beckmann.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 212)
      }
    }
    madeInGermany: file(relativePath: { eq: "logos/made-in-germany.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    bannerTop: file(relativePath: { eq: "banners/Banner_Home_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }
    elektroteks: file(
      relativePath: { eq: "banners/Banner_Elektroteks_news_eng.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }
    elektroteksMobile: file(
      relativePath: { eq: "banners/Banner_Elektroteks_news_Mobile_eng.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1080)
      }
    }
    bannerBottom: file(relativePath: { eq: "banners/Banner_2_home.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
  }
`
